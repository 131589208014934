import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import "../index.css";
const TermComponent = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <Button variant="primary" onClick={handleShowModal} className="term-btn">
        <h4 className="underlined-text"> *Terms and conditions here </h4>
      </Button>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            <span className="english-title">T&Cs :</span>
            <span className="arabic-title">الشروط والأحكام:</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul>
            <li>1 spin per table.</li>
            <li>Meal prize valid for next dine-in visit in same location.</li>
            <li>Draw for AED 10,000 cash will be held on October 1st</li>
          </ul>
          <ul className="arabic">
            <li> يمكنك الدخول في السحب مرة واحدة لكل طاولة. </li>
            <li>لا يمكن استخدام هذه القسيمة مع أي خصم أو عرض آخر.</li>
            <li>
            جائزة الوجبة المجانية صالحة للزيارة القادمة وفقط في هذا الفرع
             </li>

            <li> سيقام السحب على 10،000 درهم نقداً في الأول من أكتوبر</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TermComponent;
