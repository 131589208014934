import React, { useEffect, useState } from "react";
import Form from "../../components/form/form.component.jsx";
import Message from "../../components/message/message.component.jsx";
import logo from "../../img/OF_Opening_QR-11.png";
import Loading from "../../components/message/loading.component.jsx";

const HomePage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const id = urlParams.get("id");
  const currency = urlParams.get("c");
  const [nationalities, setFormNationalities] = useState();
  const [showFrom, setShowForm] = useState(1);

  const getData = async (id) => {
    try {
      // const id = "AD-Opening";

      //   `https://backend-landingpage.azurewebsites.net/form/slug?slug=${id}`
      // `http://localhost:8089/form/slug?slug=${id}`
      const response = await fetch(
        `https://backend-landingpage.azurewebsites.net/form/slug?slug=${id}`
      );
      const responseData = await response.json();

      if (responseData.success) {
        setFormNationalities(responseData.body.nationalities.body);
        setShowForm(2);
      } else {
        setShowForm(3);
      }
    } catch (error) {
      // console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    getData(id);
    // console.log(id);
  }, [id]);

  return (
    <div>
      <img src={logo} alt="logo" className="logo" />

      {showFrom === 2 ? (
        <Form nationalities={nationalities} id={id} currency={currency}/>
      ) : showFrom === 3 ? (
        <Message />
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default HomePage;
